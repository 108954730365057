export const UNIT_FOR_DISTANCE = {
  Meters: "Meters",
  Kilometers: "Kilometers",
  Miles: "Miles",
  Yards: "Yards",
  Feet: "Feet",
};
export const ORIENTATION_LABEL = "Shape";
export const earliestDateTZRegion = "Europe/London";
export const latestDateTZRegion = "Europe/London";
export const demographicSourceName = "GEOPATH";
export const UNIT_ABBREVIATION = {
  METER: "(Meters)",
  KILOMETER: "(Kilometers)",
  MILE: "(Miles)",
  FEET: "(Feet)",
  YARD: "(Yards)",
};
export const ROUTE = "ROUTE";
export const CAMPAIGN_PROBABILITIES = [0, 30, 60, 90, 100];
export const DEFAULT_AUDIENCE_NAME = "";
export const DEFAULT_AUDIENCE_QUERY = "";
export const GEOGRAPHY_REACH_WARNING =
  "You have not entered the relevant geography for this schedule and as a result, this will be treated as a National Reach when selecting frames and determining average Impacts.";
export const FREE_FORM_CONTAINER = "FREE_FORM_CONTAINER";
export const DEFAULT_US_AGENCY_COMMISSION = 0;
export const DEFAULT_US_SAC_VALUE = 0;
export const DEFAULT_US_SAC_PASSED_BACK = false;
export const DDS_PRINT = { Magazine: "M", Other: "N" };
