import { CacheLocation } from "@auth0/auth0-spa-js";

const cacheLocationImpl: CacheLocation = "localstorage" as CacheLocation;

export const getEnvironment = (baseUrl: string) => ({
  baseUrl,
  production: false,
  client_id: "talon_web_client",
  client_secret: "zsy6]cqzvqnZgJYkFXvpyJGMbN",
  auth0: {
    domain: "auth-test.plato2.talonoutdoor.com",
    clientId: "tiaOX6iv1XsbROBrXN0rZJH5q8f2F5XM",
    audience: "https://plato2us.talonoutdoor.com/api/",
    redirectUri: "https://qa.plato2us.talonoutdoor.com/login",
    cacheLocation: cacheLocationImpl,
  },
  aptBypass: false,
  mapBoxTilesUrl: "mapbox://styles/talonodmp/cjvxd5f925v6g1cs8f4cvxrwd",
  accessTokenMapBox: "pk.eyJ1IjoidGFsb25vZG1wIiwiYSI6ImNqb2U2bWZlcDFsdHEzb253b2Vodnd3amUifQ.8ST92gdUUWdQROf8d7mHpA",
  envName: "qa",
  optioningMode: {},

  userRoleByRegion: "ENVIRONMENT_US_QA",
  supportedRegions: ["US", "CA"],
  defaultRegion: "US",
  decodedAccessTokenKey: "https://plato.talonoutdoor.com",
});
